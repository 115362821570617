//import CryptoJS from 'crypto-js';

const Utils = {
  /**
   * 50525c3fbe3b9218 为 lgsc md5加密的值
   */
  // encrypt(data, key = '50525c3fbe3b9218') {
  //   let encJson = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  //   return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  // },
  // decrypt(data, key = '50525c3fbe3b9218') {
  //   let decData = CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
  //   return decodeURIComponent(CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8).replace(/"/g, ''));
  // },
  // encrypt(data, key = '50525c3fbe3b9218') {
  //   let encJson = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  //   return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  // },
  // decrypt(data, key = '50525c3fbe3b9218') {
  //   let decData = CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
  //   return decodeURIComponent(CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8).replace(/"/g, ''));
  // },
  getCookies(key){
    
    if(!window.document ||!window.document.cookie)
      return '';
    const cookies=window.document.cookie.split(';');
    for(let i=0;i<cookies.length;i++){
      if(cookies[i].split('=')[0].trim()==key){
        const value=cookies[i].split('=')[1];
        if(value || value==='0')
          return value;
      }
    }
    return '';
  }, 
  setCookies(key,value){
    if(!window.document)
      return;
    const expires=(value || value==='0')?(3600*1000*24):-1;
    console.log(key+'='+value+';expires='+expires);
    window.document.cookie=key+'='+value+';expire='+expires+';path=/';
  },
  clearCookies(key){
    if(!window.document || !window.document.cookie)
      return;
    window.document.cookie=key+'=;expire=-1;path=/';

  },
  urlQuery(){
    let obj={}
    if(window.location.search)
      window.location.search.replace('?','').split('&').map((item,index)=>{
        const vals=item.split('=');
        obj[vals[0]]=vals[1];
      })
    return obj;
  },
  valid(value,type = 'null'){
    let result=false;
    switch(type){
      case 'null':
        result = !(value.trim() === '');
        break;
      case "mobile":
        result = (/^1[3-9]\d{9}$/.test(value))
        break;
      case "idCard":
        if(value.length==18){
          value=value.toUpperCase();
          let coe=[7,9,10, 5, 8, 4, 2 ,1 ,6 ,3 ,7, 9, 10, 5 ,8 ,4 ,2];
          let code=[1,0,'X',9,8,7,6,5,4,3,2];
          let total=0;
          for(let i=0;i<value.length;i++){
            if(i==17){
              let v=total%11;
              if(value[i]==code[v]){
                result=true;
              }
            }else{
              let num=parseInt(value[i]);
              total+=num*coe[i];
            }
          }
        }
        break;
      case "email":
        result = /^[0-9a-zA-Z\.\-]*@[0-9a-zA-Z]+.[a-zA-Z]+$/.test(value);
        break;
    }
    return result;
  },
  message(params,type){
    let ctor={
      message:'',
      delay:3000,
      type:'message',
      style:'light',
      id:''
    }
    if(typeof params == 'string')
     ctor.message=params 
    else{
     //ctor=params;
     Object.keys(params).map(key=>{ctor[key]=params[key]});
    }
    ctor.id=new Date().valueOf();
    const div=document.createElement('div');
    div.className='z-message';
    div.id='zMessage'+ctor.id;
    let style={
      position:'fixed',left:0,top:0,width:'100vw',height:'0vw',zIndex:99,display:'flex',justifyContent:'center',fontSize:'14px'
    };
    Object.keys(style).map(key=>{div.style[key]=style[key]});
    const comps=['','-webkit-','-moz-','-ms-'];
    const start=comps.map(comp=>(comp+'transform:translateY(-100px);')).join('')+'opacity:0;';
    const end=[...comps.map(comp=>([comp+'transform','translateY(0px)'])),['opacity','1']];
    const transition=comps.map(comp=>comp+'transition:all .5s linear;');
    const iconStyle={
      message:{color:'',icon:'&#xe600;'},
      error:{color:'red',icon:'&#xe663;'},
      success:{color:'#090',icon:'&#xe60c;'}
    }
    const icon=`<span class="iconfont" style="color:${iconStyle[ctor.type].color}">${iconStyle[ctor.type].icon}</span>`
    div.innerHTML=`<div 
      class="z-message-content" 
      style="width:400px;margin-top:100px;
      background:#FFF;padding:20px;border-radius:5px;box-shadow:#000A 0 0 5px;position:fixed;left:50%${start+transition}">
      
      ${icon} ${ctor.message}
    </div>`;
    document.documentElement.appendChild(div);
    
    setTimeout(()=>{
      end.map(style=>{
        document.querySelector('#'+div.id+' .z-message-content').style[style[0]]=style[1];
      });
    },10);

    setTimeout(()=>{
      document.querySelector('#'+div.id+' .z-message-content').style.opacity=0;
      setTimeout(()=>{
        document.documentElement.removeChild(div);
      },250)
    },ctor.delay-250);
    
  },
  
};

export default Utils;
