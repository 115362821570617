import React,{useState} from 'react';

import './CallUsMain.scss';

const CallUsMain =(props)=>{
  return <div className="CallUsMain">
    <div className="callus-ercode">
        <img src={require('../../../assets/images/yls-ercode.png').default}/>
    </div>
    <div className="callus-content">
      <h1><img src={require('../../../assets/images/call-title.png').default}/></h1>
      <p>联系人：叶老师</p>
      <p>电　话：15623088490（微信同号）</p>
      <p>邮　箱：necispr@duoyue.me</p>
      <p><label>地　址：</label><span>湖北省武汉市洪山区关山大道43号泛悦城T2写字楼27层编创赛组委会 叶老师（收）15623088490（邮编：430070）</span></p>
    </div>
  </div>
}


export default CallUsMain;


